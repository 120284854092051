<template>
  <MainWrapper :width="width" :boxShadow="boxShadow" :border="border">
    <TitleWrapper
      v-gtm.click="{
        category: 'home',
        action: 'click',
        label: titulo,
      }"
      :isOpen="isOpen"
    >
      <Title :obrigatorio="obrigatorio" @click="setIsOpen">{{ titulo }}</Title>
      <slot name="titulo"></slot>
      <img
        src="@/assets/landing-page/expand.webp"
        @click="setIsOpen"
        alt="plus icon"
        width="20"
        height="20"
      />
    </TitleWrapper>
    <ContentWrapper :isOpen="isOpen">
      <Content>
        <slot name="conteudo"></slot>
        <div v-if="texto">{{ texto }}</div>
      </Content>
    </ContentWrapper>
  </MainWrapper>
</template>

<script>
import styled from 'vue-styled-components';

const props = {
  width: String,
  height: String,
  isOpen: Boolean,
  obrigatorio: Boolean,
  boxShadow: Boolean,
  border: Boolean,
};

const MainWrapper = styled('div', props)`
  width: ${({ width }) => (width ? width + 'px' : '100%')};
  min-height: ${({ height }) => (height ? height + 'px' : 'auto')};
  border-bottom: 1px solid #e1e1e1;

  @media screen and (min-width: 320px) and (max-width: 1023px) {
    width: 100%;
    margin: auto;
  }
`;
const TitleWrapper = styled('div', props)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : '')};
    width: 20px;
    height: 20px
    transition: transform 0.2s;
    user-select: none;
    cursor: pointer;
  }
`;
const Title = styled('h2', props)`
  font-size: 16px;
  font-family: 'Mulish';
  width: 400px;
  color: #616161;
  cursor: pointer;

  &:after {
    content: '${({ obrigatorio }) => (obrigatorio ? '*' : '')}';
    color: red;
  }

  @media screen and (min-width: 320px) and (max-width: 1023px) {
    font-size: 16px;
    width: 100%;
  }
`;

const ContentWrapper = styled('div', props)`
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.2s;

  @media screen and (min-width: 320px) and (max-width: 1023px) {
    max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  }
`;

const Content = styled.p`
  word-wrap: break-word;
  text-align: left;
  padding: 0;
  font-size: 14px;
`;

export default {
  name: 'Accordion',

  components: {
    MainWrapper,
    TitleWrapper,
    Title,
    ContentWrapper,
    Content,
  },

  props: {
    width: String,
    titulo: String,
    texto: String,
    id: Number,
    obrigatorio: Boolean,
    boxShadow: Boolean,
    border: Boolean,
    initOpen: Boolean,
  },

  data: () => ({
    isOpen: false,
    politicas: [],
  }),

  created() {
    this.isOpen = this.initOpen;
  },

  methods: {
    setIsOpen: function () {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
